<template>
  <div class="page_">
    <!-- 第三方管理服务 -->
    <div class="navbar_">
      <div class="main_">
        <img src="../../assets/medilink/product/header.jpg" alt="" />
        <div class="text_">
          <p>{{ $t("PAS[1]") || "第三方管理服务" }}</p>
        </div>
      </div>
      <navbar :showHeader="showHeader" :nowIndex="4"></navbar>
    </div>
    <!-- 专业的第三方管理服务 -->
    <div class="w">
      <p class="title_ animate">{{ $t("PAS[6]") || "专业的第三方管理服务" }}</p>
      <div class="third_ w">
        <img src="../../assets/medilink/product/service.jpg" alt="" />
        <div class="text_ animate">
          <p class="s_title_">{{ $t("PAS[7]") || "服务概述" }}</p>
          <div class="s_section_">
            <span>
              {{
                $t("PAS[8]") ||
                  "中间带通过先进的电子医疗卡网络架构实现医疗理赔处理，提供第三方理赔管理服务。中间带拥有在中国唯一的安全、值得信赖的电子医疗卡直付网络，并为中、外保险公司及自保企业提供中国、大中华区、亚洲及覆盖全球的健康保险第三方管理服务。"
              }}
            </span>
            <br /><br />
            <span class="s_p_1_">
              {{
                $t("PAS[9]") ||
                  "中间带系统可以实现保险福利实时在线确认，还可以进行门诊理赔实时自动理算，每一位会员都持有一张电子医疗卡，会员需要在就医时出示该卡。医院工作人员会帮助会员在中间带的EDC终端机进行刷卡操作，系统可以在几秒钟内完成与后台系统的数据传输。"
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务介绍 -->
    <div class="w service_">
      <p class="title_ ser_title_ animate">{{ $t("PAS[10]") || "服务介绍" }}</p>
      <ul>
        <li class="service_introduce_">
          <img
            class="sev_img_"
            src="../../assets/medilink/product/icon1.png"
            alt=""
          />
        </li>
        <li>
          <img
            class="sev_img_"
            src="../../assets/medilink/product/icon2.png"
            alt=""
          />
        </li>
        <li>
          <img
            class="sev_img_"
            src="../../assets/medilink/product/icon3.png"
            alt=""
          />
        </li>
        <li>
          <img
            class="sev_img_"
            src="../../assets/medilink/product/icon4.png"
            alt=""
          />
        </li>
        <li>
          <img
            class="sev_img_"
            src="../../assets/medilink/product/icon5.png"
            alt=""
          />
        </li>
      </ul>
    </div>
    <div class="line_">
      <div class="w line_dot_">
        <span class="dot_1_"></span>
        <span class="dot_1_ dot_2_"></span>
        <span class="dot_1_ dot_3_"></span>
        <span class="dot_1_ dot_4_"></span>
        <span class="dot_1_ dot_5_"></span>
      </div>
    </div>
    <div class="w line_con_">
      <div class="line_text_ line_text_1_">
        {{
          $t("PAS[11]") ||
            `在线就诊前身份确认在线就诊后理赔数据
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;捕捉和确认`
        }}
      </div>
      <div class="line_text_ line_text_2_">
        {{ $t("PAS[12]") || "理赔付款处理含直接付款服务" }}
      </div>
      <div class="line_text_ line_text_3_">
        {{ $t("PAS[13]") || "医疗机构担保付费的行政处理" }}
      </div>
      <div class="line_text_ line_text_4_">
        {{ $t("PAS[14]") || "住院跟踪及出院前医疗费用审查" }}
      </div>
      <div class="line_text_ line_text_5_">
        {{ $t("PAS[15]") || "未核准诊断确认沟通理赔申请文档收集" }}
      </div>
    </div>
    <!-- 会员将享受到的福利 -->
    <div class="member_ w">
      <p class="title_ mem_title_ animate">
        {{ $t("PAS[16]") || "会员将享受到的福利" }}
      </p>
      <div class="icon_">
        <div class="cicle_ cicle_1_">
          <img
            class="img_"
            src="../../assets/medilink/product/member_list_1.png"
            alt=""
          />
          <span>{{ $t("PAS[17]") || "在线实时身份验证" }}</span>
        </div>
        <div class="cicle_ cicle_2_">
          <img
            class="img_"
            src="../../assets/medilink/product/member_list_2.png"
            alt=""
          />
          <span>{{
            $t("PAS1[0]") || "安全、可靠、保密、并已经得到认证的技术。"
          }}</span>
        </div>
        <div class="cicle_ cicle_3_">
          <img
            class="img_"
            src="../../assets/medilink/product/member_list_3.png"
            alt=""
          />
          <span>{{ $t("PAS1[1]") || "省时高效的实时报表系统" }}</span>
        </div>
        <div class="cicle_ cicle_4_">
          <img
            class="img_"
            src="../../assets/medilink/product/member_list_4.png"
            alt=""
          />
          <span>{{ $t("PAS1[2]") || "简便的注册方式和无纸化管理" }}</span>
        </div>
        <div class="cicle_ cicle_5_">
          <img
            class="img_"
            src="../../assets/medilink/product/member_list_4.png"
            alt=""
          />
          <span>{{ $t("PAS1[3]") || "先进的，安全可信的电子医疗卡网络" }}</span>
        </div>
        <div class="cicle_ cicle_6_">
          <img
            class="img_"
            src="../../assets/medilink/product/member_list_6.png"
            alt=""
          />
          <span>{{ $t("PAS1[4]") || "范围广、数量多的网络医疗机构" }}</span>
        </div>
        <div class="cicle_ cicle_7_">
          <img
            class="img_"
            src="../../assets/medilink/product/member_list_7.png"
            alt=""
          />
          <span>{{
            $t("PAS1[5]") || "拥有跨区域的理赔经验，提供专业的风险管控。"
          }}</span>
        </div>
      </div>
    </div>
    <!-- 特色服务 -->
    <p class="sp_title_ title_ animate">
      <img src="../../assets/medilink/product/special.png" alt="" />
      <span>{{ $t("PAS1[6]") || "特色服务" }}</span>
    </p>
    <div class="sp_con_ w">
      <img src="../../assets/medilink/product/special2.jpg" alt="" />
      <div class="sp_text_ animate">
        <p class="sp_txt_title_">
          {{ $t("PAS1[7]") || "电子理赔管理系统介绍" }}
        </p>
        <p class="sp_sec_">
          {{
            $t("PAS1[8]") ||
              "中间带系统通过支持电子医疗卡网络为保险公司、企业和第三方管理机构提供医疗理赔以及医疗数据的管理服务。"
          }}
        </p>
      </div>
    </div>
    <!-- 安全可信赖的技术 -->
    <div class="w teg_">
      <p class="title_ teg_title_ animate">
        {{ $t("PAS1[9]") || "安全可信赖的技术" }}
      </p>
      <div class="teg_con_">
        <div class="dot_sec_">
          <img class="dot" src="../../assets/medilink/product/dot.png" alt="" />
        </div>
        <div class="teg_text_">
          <div class="teg_txt_title_ animate">
            <div>
              {{
                $t("PAS1[10]") ||
                  "部署在医疗机构的每一台EDC终端都装有中间带的专有软件，用来验证中间带发出的医疗卡。类似信用卡验证程序，当持卡人就诊时，医疗机构的前台工作人员可在指定的EDC终端上刷中间带医疗卡为持卡人进行身份确认。"
              }}
            </div>
            <div>
              {{
                $t("PAS[11]") ||
                  "中间带的ECCS是一套高度定制的适用于医疗理赔处理和医疗数据管理的应用系统。该系统设计基于实现对医疗费用给付方、会员，以及他们的健康计划、福利、责任、除外条款和保前疾病的各种数据库的有效管理。"
              }}
            </div>
          </div>
        </div>
        <img
          class="teg_img_"
          src="../../assets/medilink/product/teg.jpg"
          alt=""
        />
      </div>
    </div>
    <!-- 给用户带来的好处 -->
    <div class="benefit_ w">
      <p class="title_ benefit_title_ animate">
        {{ $t("PAS1[12]") || "给用户带来的好处" }}
      </p>
      <div class="benefit_con_">
        <div class="cicle_ cicle_1_">
          <img
            class="img_"
            src="../../assets/medilink/product/user_hc_1.png"
            alt=""
          />
          <span>{{
            $t("PAS1[13]") || "电子实时在线的身份确认和福利确认"
          }}</span>
        </div>
        <div class="cicle_ cicle_2_">
          <img
            class="img_"
            src="../../assets/medilink/product/user_hc_2.png"
            alt=""
          />
          <span>{{ $t("PAS1[14]") || "灵活、通用并且高度可定制化" }}</span>
        </div>
        <div class="cicle_ cicle_3_">
          <img
            class="img_"
            src="../../assets/medilink/product/user_hc_3.png"
            alt=""
          />
          <span>{{
            $t("PAS1[15]") ||
              "通过最新型最优良的应用程序和技术提供最高标准的服务"
          }}</span>
        </div>
        <div class="cicle_ cicle_4_">
          <img
            class="img_"
            src="../../assets/medilink/product/user_hc_4.png"
            alt=""
          />
          <span>{{ $t("PAS1[16]") || "安全可信赖的电子医疗网络" }}</span>
        </div>
      </div>
    </div>
    <!-- 系统如何工作 -->
    <div class="work_ w">
      <p class="title_ sys_title_ animate">
        {{ $t("PAS1[17]") || "系统如何工作" }}
      </p>
      <div class="sys_txt_ animate">
        {{
          $t("PAS1[18]") ||
            "中间带系统是由前端电子数据采集终端（EDCT）网络和我们定制电子理赔处理系统支持的后台数据中心共同组成的。"
        }}
      </div>
    </div>
    <div class="foot_circle_">
      <div class="work_sec_">
        <div class="w_hover_ w_cicle_ w_cicle_1_">
          <img
            class="img_"
            src="../../assets/medilink/product/work1.png"
            alt=""
          />
          <span>{{ $t("PAS1[19]") || "齿科诊所" }}</span>
        </div>
        <div class="w_hover_ w_cicle_ w_cicle_2_">
          <img
            class="img_"
            src="../../assets/medilink/product/work2.png"
            alt=""
          />
          <span>{{ $t("PAS1[23]") || "药店" }}</span>
        </div>
        <div class="w_hover_ w_cicle_ w_cicle_3_">
          <img
            class="img_"
            src="../../assets/medilink/product/work3.png"
            alt=""
          />
          <span>{{ $t("PAS1[24]") || "检查室" }}</span>
        </div>
        <div class="w_hover_ w_cicle_ w_cicle_4_">
          <img
            class="img_"
            src="../../assets/medilink/product/work4.png"
            alt=""
          />
          <span>{{ $t("PAS1[25]") || "诊所" }}</span>
        </div>
        <div class="w_hover_ w_cicle_ w_cicle_5_">
          <img
            class="img_"
            src="../../assets/medilink/product/work5.png"
            alt=""
          />
          <span>{{ $t("PAS1[26]") || "医院" }}</span>
        </div>
        <div class="w_cicle_ w2_cicle_ w2_cicle_1_">
          <img
            class="img_"
            src="../../assets/medilink/product/work_2_yl.png"
            alt=""
          />
          <span>{{ $t("PAS1[20]") || "医疗管理组织" }}</span>
          <span>（HMO）</span>
        </div>
        <div class="w_cicle_ w2_cicle_ w2_cicle_2_">
          <img
            class="img_"
            src="../../assets/medilink/product/work_2_bx.png"
            alt=""
          />
          <span>{{ $t("cols[6]") || "保险公司" }}</span>
        </div>
        <div class="w_cicle_ w2_cicle_ w2_cicle_3_">
          <img
            class="img_"
            src="../../assets/medilink/product/work_2_gz.png"
            alt=""
          />
          <span>{{ $t("PAS1[28]") || "公估公司" }}</span>
        </div>
        <div class="w_cicle_ w2_cicle_ w2_cicle_4_">
          <img
            class="img_"
            src="../../assets/medilink/product/work_2_tt.png"
            alt=""
          />
          <span>{{ $t("PAS1[29]") || "企业团体" }}</span>
        </div>
        <div class="w_cicle_ w2_cicle_ w2_cicle_5_">
          <img
            class="img_"
            src="../../assets/medilink/product/work_2_gl.png"
            alt=""
          />
          <span>{{ $t("PAS1[30]") || "第三方管理机构" }}</span>
          <span>（TPA）</span>
        </div>
        <div class="logo_">
          <img src="../../assets/medilink/product/logo.png" alt="" />
        </div>
      </div>
      <div class="foot_bgm_">
        <img src="../../assets/medilink/product/foot_circle.png" alt="" />
      </div>
    </div>
    <div class="window_ ">
      <router-link :to="{ name: 'mainland' }">
        <img
          src="../../assets/medilink/product/icon0.png"
          alt=""
          class="BX_Class1 BX_BG1 BX_Ani1"
        />
      </router-link>
    </div>
    <footerbar></footerbar>
    <sideBar v-if="showBar"></sideBar>
  </div>
</template>
<script>
import footerbar from "./components/footer.vue";
import navbar from "./components/navbar.vue";
import sideBar from "./components/sideBar.vue";
export default {
  components: { navbar, footerbar, sideBar },
  data() {
    return {
      showHeader: false,
      height: 0,
      nowIndex: 0,
      showBar: false
    };
  },
  mounted() {
    window.onscroll = () => {
      this.handleScroll();
    };
    this.startAnimation();
  },
  methods: {
    handleScroll(e) {
      var top = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageXOffset
      );
      this.height = top;
      this.startAnimation();
    },
    startAnimation() {
      let elementArr = document.querySelectorAll(".animate");
      let bodyHeight = document.body.clientHeight;
      let heightArr = [];
      for (var i = 0; i < elementArr.length; i++) {
        heightArr.push(elementArr[i].offsetTop + 100);
      }
      for (var j = 0; j < heightArr.length; j++) {
        if (
          heightArr[j] >= this.height &&
          heightArr[j] < this.height + bodyHeight
        ) {
          elementArr[j].classList.add("slideUpIn60");
        }
      }
    }
  },
  watch: {
    height: {
      handler(newHeight, oldHeight) {
        if (newHeight > 400) {
          this.showBar = true;
          if (newHeight > oldHeight) {
            this.showHeader = false;
          } else {
            this.showHeader = true;
          }
        } else {
          this.showHeader = false;
          this.showBar = false;
        }
      }
    }
  }
};
</script>
<style lang="scss">
.page_ {
  padding: 0;
  .w {
    // border: 1px solid #ccc;
    width: 1200px;
    margin: 0 auto;
  }
  .navbar_ {
    position: relative;
    width: 100%;
    .main_ {
      width: 100%;
      position: relative;
      color: #fff;
      & > img {
        height: auto;
        width: 100%;
        object-fit: cover;
      }
      .text_ {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        font-weight: bold;
        font-size: 33px;
        text-align: center;
      }
    }
  }
  .title_ {
    margin: 74px auto 75px;
    // width: 262px;
    height: 32px;
    text-align: center;
    font-size: 24px;
    font-family: MicrosoftYaHeiUISemibold;
    color: #000;
    line-height: 32px;
  }

  .third_ {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 50%;
    }
    .text_ {
      padding-left: 41px;
      padding-top: 47px;
      width: 50%;
      // height: 100%;
      background-color: #fbfbfb;
      .s_title_ {
        height: 24px;
        font-size: 18px;
        color: #000;
        line-height: 24px;
      }
      .s_section_ {
        width: 509px;
        // height: 216px;
        font-size: 14px;
        font-family: MicrosoftYaHeiUI;
        color: #333;
        line-height: 24px;
        margin-top: 17px;
      }
    }
  }
  .service_ {
    .ser_title_ {
      text-align: center;
    }
    ul {
      margin-top: 74px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      > li {
        flex: 1;
        list-style: none;
        margin-right: 107px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 154px;
          height: 154px;
        }
      }
    }
  }
  .line_ {
    height: 1px;
    background-color: rgba(56, 165, 76, 0.5);
    margin-top: 33px;
    .line_dot_ {
      position: relative;
      .dot_1_ {
        position: absolute;
        left: 58px;
        transform: translateY(-50%);
        width: 16.7px;
        height: 16.7px;
        background: #00a63c;
        // background-color:rgba(56,165,76,0.5);
        border-radius: 50%;
      }
      .dot_2_ {
        left: 324px;
      }
      .dot_3_ {
        left: 591px;
      }
      .dot_4_ {
        left: 854px;
      }
      .dot_5_ {
        left: 1116px;
      }
    }
  }
  .line_con_ {
    margin-top: 24px;
    position: relative;
    .line_text_ {
      position: absolute;
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      line-height: 19px;
    }
    .line_text_1_ {
      left: -2px;
      width: 139px;
      height: 57px;
    }
    .line_text_2_ {
      left: 279px;
      width: 106px;
      height: 38px;
    }
    .line_text_3_ {
      left: 544px;
      width: 107px;
      height: 38px;
    }
    .line_text_4_ {
      left: 795px;
      width: 135px;
      height: 38px;
    }
    .line_text_5_ {
      left: 1058px;
      width: 135px;
      height: 38px;
    }
  }

  .member_ {
    .mem_title_ {
      margin: 140px auto 60px;
    }
    .icon_ {
      margin-top: 75px;
      height: 276px;
      border: 1px solid #eee;
      .cicle_ {
        margin-top: 34px;
        float: left;
        display: flex;
        align-items: center;
        padding-left: 49px;
        text-align: center;
        height: 48px;
        background: #f7fcf9;
        // font-weight: 700;
        border-radius: 24px;
        .img_ {
          width: 22px;
          height: 22px;
        }
        span {
          padding-left: 10px;
        }
      }
      .cicle_1_ {
        width: 242px;
        margin-left: 59px;
        margin-right: 78px;
      }
      .cicle_2_ {
        width: 410px;
        margin-right: 71px;
      }
      .cicle_3_ {
        width: 280px;
      }
      .cicle_4_ {
        width: 363px;
        margin-left: 172px;
        margin-right: 108px;
      }
      .cicle_5_ {
        width: 388px;
      }
      .cicle_6_ {
        width: 396px;
        margin-left: 59px;
        margin-right: 108px;
      }
      .cicle_6_ {
        width: 582px;
      }
    }
  }
  .sp_title_ {
    color: rgba(0, 166, 60, 1);
    margin-top: 79px;
    margin-bottom: 83px;
    img {
      width: 21px;
      height: 28px;
      margin-right: 13px;
      margin-left: 61px;
    }
    > span {
      font-size: 24px;
    }
  }
  .sp_con_ {
    height: 350px;
    > img {
      width: 50%;
      height: 100%;
    }
    .sp_text_ {
      background-color: #fbfbfb;
      float: right;
      padding-left: 41px;
      width: 50%;
      height: 100%;
      .sp_txt_title_ {
        font-weight: 700;
        margin-top: 132px;
        margin-bottom: 16px;
        height: 24px;
        font-size: 18px;
        color: #000;
        line-height: 24px;
      }
      .sp_sec_ {
        width: 509px;
        height: 170px;
        font-size: 14px;
        color: #333;
        line-height: 24px;
      }
    }
  }
  .teg_ {
    .teg_title_ {
      margin: 82px auto;
      // margin-top: 82px;
      // margin-bottom: 83px;
      // margin-left: 793px;
    }
    .teg_con_ {
      height: 381px;
      .dot_sec_ {
        float: left;
        height: 100%;
        width: 76px;
        .dot {
          width: 100%;
          height: 62px;
        }
      }
      .teg_text_ {
        float: left;
        width: 35%;
        height: 100%;
        margin-left: 30px;
        .teg_txt_title_ {
          line-height: 24px;
          width: 423px;
          font-size: 14px;
          transform: translateY(50%);
          div {
            width: 100%;
          }
        }
      }
      .teg_img_ {
        float: right;
        width: 50%;
        height: 100%;
      }
    }
  }
  .benefit_ {
    .benefit_title_ {
      margin: 82px auto 83px;
      // margin-top: 83px;
      // margin-bottom: 82px;
      // margin-left: 795px;
    }
    .benefit_con_ {
      height: 198px;
      border: 1px solid #eee;
      .cicle_ {
        margin-top: 42px;
        float: left;
        display: flex;
        align-items: center;
        padding-left: 49px;
        text-align: center;
        height: 48px;
        background: rgba(0, 166, 60, 0.03);
        border-radius: 24px;
        .img_ {
          width: 22px;
          height: 22px;
        }
        span {
          padding-left: 10px;
        }
      }
      .cicle_1_ {
        width: 345px;
        margin-left: 176px;
        margin-right: 77px;
      }
      .cicle_2_ {
        width: 336px;
      }
      .cicle_3_ {
        width: 464px;
        margin-left: 238px;
        margin-right: 20px;
      }
      .cicle_4_ {
        width: 322px;
      }
    }
  }
  .work_ {
    .sys_title_ {
      margin-top: 83px;
      margin-bottom: 19px;
    }
    .sys_txt_ {
      text-align: center;
      font-family: MicrosoftYaHeiUI;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 172px;
    }
  }
  .window_ {
    position: fixed;
    right: 0;
    bottom: 60vh;
    width: 119px;
    height: 92px;
    z-index: 10;
    img {
      width: 119px;
      height: 92px;
      transform-style: preserve-3d;
      transform: translate3D(0, 0, 0px) rotate(0deg) scale(1.1, 1.1);
      opacity: 1;
      transform-origin: 50% 50%;
    }
  }
  .foot_circle_ {
    margin: 0 auto;
    width: 1200px;
    height: 385px;
    .foot_bgm_ {
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .work_sec_ {
      position: relative;
      width: 100%;
      height: 1px;
      background-color: #fff;
      .logo_ {
        position: absolute;
        left: 50%;
        top: 289px;
        width: 200px;
        height: 66px;
        transform: translateX(-50%);
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .w_cicle_ {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        margin-bottom: 5px;
        span {
          margin-top: 9px;
        }
      }
      .w_hover_ {
        .img_ {
          width: 101px;
          height: 100px;
          &:hover {
            transition: all 0.6s;
            transform: scale(1.1);
          }
        }
      }
      .w2_cicle_ {
        .img_ {
          width: 28px;
          height: 28px;
        }
      }
      .w_cicle_1_ {
        left: 140px;
        top: 104px;
      }
      .w_cicle_2_ {
        left: 304px;
        top: -13px;
      }
      .w_cicle_3_ {
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
      }
      .w_cicle_4_ {
        left: 783px;
        top: -13px;
      }
      .w_cicle_5_ {
        left: 983px;
        top: 101px;
      }
    }
    .w2_cicle_1_ {
      left: 226px;
      top: 259px;
    }
    .w2_cicle_2_ {
      left: 383px;
      top: 182px;
    }
    .w2_cicle_3_ {
      left: 50%;
      top: 171px;
      transform: translate(-50%, -50%);
    }
    .w2_cicle_4_ {
      left: 757px;
      top: 182px;
      .img_ {
        width: 36px !important;
      }
    }
    .w2_cicle_5_ {
      left: 874px;
      top: 259px;
      .img_ {
        height: 36px !important;
      }
    }
  }
}
/* Generated via BX-AE2CSS by BigXiXi */
// .BX_AEComposition{
//     background: transparent;
//     width: 500px;
//     height: 500px;
//     display: block;
//     overflow: hidden;
//     position: relative;
//     transform: translate3D(0,0,0) scale(1,1);
//     transform-origin:50% 0%;
// }
// .BX_Class1{
//     left: 117.00px;
//     top: 147.00px;
//     width: 266px;
//     height: 206px;
//     transform-style: preserve-3d;
//     transform: translate3D(0,0,0.00px) rotate(0.00deg) scale(1.10,1.10);
//     opacity: 1.00;
//     transform-origin:50.00% 50.00%;
//     display: block;
//     position: absolute;
// }
// .BX_BG1{
//     background: url("imgs/%E7%BC%96%E7%BB%84%2014@2x.png");
// }
.BX_Ani1 {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: BX_AniKey1 2.67s steps(1) 0s infinite normal none;
}
@keyframes BX_AniKey1 {
  0.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.09708737864078, 1.09708737864078);
  }
  1.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.09983737864078, 1.09983737864078);
  }
  2.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.10258737864078, 1.10258737864078);
  }
  3.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.10533737864078, 1.10533737864078);
  }
  5.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.10808737864078, 1.10808737864078);
  }
  6.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.11083737864078, 1.11083737864078);
  }
  7.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.11358737864078, 1.11358737864078);
  }
  8.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.11633737864078, 1.11633737864078);
  }
  10.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.11908737864078, 1.11908737864078);
  }
  11.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.12183737864078, 1.12183737864078);
  }
  12.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.12458737864078, 1.12458737864078);
  }
  13.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.12733737864078, 1.12733737864078);
  }
  15.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.13008737864078, 1.13008737864078);
  }
  16.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.13283737864078, 1.13283737864078);
  }
  17.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.13558737864078, 1.13558737864078);
  }
  18.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.13833737864078, 1.13833737864078);
  }
  20.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.14108737864078, 1.14108737864078);
  }
  21.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.14383737864078, 1.14383737864078);
  }
  22.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.14658737864078, 1.14658737864078);
  }
  23.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.14933737864078, 1.14933737864078);
  }
  25.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.15208737864078, 1.15208737864078);
  }
  26.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.15483737864078, 1.15483737864078);
  }
  27.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.15758737864078, 1.15758737864078);
  }
  28.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.16033737864078, 1.16033737864078);
  }
  30.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.16308737864078, 1.16308737864078);
  }
  31.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.16583737864078, 1.16583737864078);
  }
  32.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.16858737864078, 1.16858737864078);
  }
  33.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.17133737864078, 1.17133737864078);
  }
  35.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.17408737864078, 1.17408737864078);
  }
  36.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.17683737864078, 1.17683737864078);
  }
  37.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.17958737864078, 1.17958737864078);
  }
  38.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.18233737864078, 1.18233737864078);
  }
  40.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.18508737864078, 1.18508737864078);
  }
  41.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.18783737864078, 1.18783737864078);
  }
  42.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.19058737864078, 1.19058737864078);
  }
  43.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.19333737864078, 1.19333737864078);
  }
  45.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.19608737864078, 1.19608737864078);
  }
  46.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.19883737864078, 1.19883737864078);
  }
  47.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.20158737864078, 1.20158737864078);
  }
  48.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.20433737864078, 1.20433737864078);
  }
  50.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.20708737864078, 1.20708737864078);
  }
  51.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.20433737864078, 1.20433737864078);
  }
  52.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.20158737864078, 1.20158737864078);
  }
  53.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.19883737864078, 1.19883737864078);
  }
  55.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.19608737864078, 1.19608737864078);
  }
  56.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.19333737864078, 1.19333737864078);
  }
  57.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.19058737864078, 1.19058737864078);
  }
  58.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.18783737864078, 1.18783737864078);
  }
  60.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.18508737864078, 1.18508737864078);
  }
  61.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.18233737864078, 1.18233737864078);
  }
  62.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.17958737864078, 1.17958737864078);
  }
  63.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.17683737864078, 1.17683737864078);
  }
  65.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.17408737864078, 1.17408737864078);
  }
  66.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.17133737864078, 1.17133737864078);
  }
  67.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.16858737864078, 1.16858737864078);
  }
  68.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.16583737864078, 1.16583737864078);
  }
  70.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.16308737864078, 1.16308737864078);
  }
  71.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.16033737864078, 1.16033737864078);
  }
  72.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.15758737864078, 1.15758737864078);
  }
  73.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.15483737864078, 1.15483737864078);
  }
  75.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.15208737864078, 1.15208737864078);
  }
  76.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.14933737864078, 1.14933737864078);
  }
  77.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.14658737864078, 1.14658737864078);
  }
  78.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.14383737864078, 1.14383737864078);
  }
  80.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.14108737864078, 1.14108737864078);
  }
  81.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.13833737864078, 1.13833737864078);
  }
  82.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.13558737864078, 1.13558737864078);
  }
  83.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.13283737864078, 1.13283737864078);
  }
  85.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.13008737864078, 1.13008737864078);
  }
  86.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.12733737864078, 1.12733737864078);
  }
  87.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.12458737864078, 1.12458737864078);
  }
  88.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.12183737864078, 1.12183737864078);
  }
  90.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.11908737864078, 1.11908737864078);
  }
  91.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.11633737864078, 1.11633737864078);
  }
  92.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.11358737864078, 1.11358737864078);
  }
  93.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.11083737864078, 1.11083737864078);
  }
  95.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.10808737864078, 1.10808737864078);
  }
  96.25% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.10533737864078, 1.10533737864078);
  }
  97.50% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.10258737864078, 1.10258737864078);
  }
  98.75% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.09983737864078, 1.09983737864078);
  }
  100.00% {
    transform: translate3D(0px, 0px, 0px) rotate(0deg)
      scale(1.09708737864078, 1.09708737864078);
  }
}
</style>
